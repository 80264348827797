@import '../../../../theme/variables.scss';

.layout-header {
	position: relative;
	flex-grow: 0;
	z-index: 10;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 80px;

	height: 64px;
	flex-shrink: 0;
	margin: 0;
	border-bottom: 1px solid var($--theme-core-layout-border-color);
	background-color: var($--theme-core-background-color);
	box-shadow: 0px 2px 10px var($--theme-core-box-shadow-color);

	@media screen and (max-width: 1590px) {
		& {
			gap: 10px;
		}
	}

	.header-image-link {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-weight: normal;
		font-size: 16px;
		line-height: 16px;

		.header-image {
			height: 32px;
			margin: 0 17px 0 27px;
		}

		.header-image-text {
			color: var($--theme-core-control-label-color);
			inline-size: max-content;
		}
	}

	.account-switcher-header-container {
		margin-right: 35px;
		z-index: 100;
	}

	.header-button-group {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 6px;
		margin-left: auto;

		.button-item {
			font-size: 16px;
			line-height: 0;
			padding: 12px;
		}
	}
}
