@import '../../theme/variables.scss';

$searchBarWidth: 450px;
$searchBarWidthM: 75%;
$searchBarWidthS: 262px;

$totalWidth: calc($searchBarWidth + 36px);
$totalWidthM: calc($searchBarWidthM + 36px);
$totalWidthS: calc($searchBarWidthS + 36px);

.search-input {
	width: 70% !important;
	float: left;

	&:focus {
		outline: none;
	}
}

.search {
	position: relative;
	width: $searchBarWidth;

	@media screen and (max-width: 1590px) {
		width: $searchBarWidthM;
	}

	@media screen and (max-width: 1590px) {
		width: $searchBarWidthS;
	}

	.search-bar-container {
		margin: 0;

		.search-bar {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			background: #ffffff;
			border: 1px solid var($--theme-core-control-border-color);
			background-color: var($--theme-core-control-background-color);
			box-sizing: border-box;
			border-radius: 2px;

			&-overlay {
				&.overlay-active {
					z-index: 200;
				}

				position: absolute;
				left: 38px;
				right: 30px;
				font-family: Arial;
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: text;

				.autocomplete-prefix {
					visibility: hidden;
					cursor: text;
				}

				.suffix-wrapper {
					flex-grow: 1;
					cursor: text;

					.autocomplete-suffix {
						background-color: var($--theme-searchbar-highlight-background-color);
						color: var($--theme-searchbar-highlight-text-color);
					}
				}
			}

			&:focus-within {
				outline: var($--theme-core-control-focus-color) solid 2px;
			}

			.search-bar-input {
				&:focus-visible {
					outline: 0;
				}
			}

			&.open {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			.search-icon {
				display: block;
				flex: none;
				color: var($--theme-core-control-border-color);
				font-size: 14px;
				line-height: 14px;
				margin: 10px;
			}

			&-input {
				border: 0;
				flex: 1 1 auto;
				color: var($--theme-core-control-text-color);
				background: transparent;
				padding: 0;
				margin: 0;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				align-self: center;
				padding: 7px 2px;
			}

			.clear-icon {
				display: block;
				flex: none;
				cursor: pointer;
				color: var($--theme-core-control-border-color);
				font-size: 11px;
				line-height: 0;
				margin: 0 10px;
			}

			.search-filter-icon {
				display: block;
				flex: none;
				cursor: pointer;
				background-color: var($--theme-core-control-background-color);
				color: var($--theme-core-text-color);
				border: 1px solid var($--theme-core-control-border-color);
				border-radius: 2px;
				font-size: 12px;
				line-height: 12px;
				padding: 4px 6px;
				margin: 0 10px 0 0;

				&:hover {
					background-color: var($--theme-core-control-alt-background-color);
				}
			}

			.search-hotkey {
				display: block;
				flex: none;
				background-color: var($--theme-core-control-background-color);
				color: var($--theme-core-control-text-color);
				border: 1px solid var($--theme-core-control-border-color);
				border-radius: 2px;
				font-size: 14px;
				line-height: 14px;
				padding: 4px 6px;
				margin: 0 10px;
				user-select: none;
			}
		}
	}

	.search-results {
		position: absolute;
		width: $searchBarWidth;
		top: 38px;
		left: 0px;
		z-index: 100;
		background-color: var($--theme-core-control-alt-background-color);
		border: 1px solid var($--theme-core-control-border-color);
		border-top: 0;
		box-sizing: border-box;
		box-shadow: 0px 20px 20px var($--theme-core-box-shadow-color);
		border-radius: 0px 0px 4px 4px;
		min-height: 65px;
		padding: 6px 0;
		max-height: 85vh;
		overflow-y: scroll;

		.search-filter-container {
			display: block;
			border: 1pt solid var($--theme-core-layout-border-color);
			margin-left: 8px;
			background-color: var($--theme-core-control-background-color);
			padding: 8px 27px 8px 8px;
			cursor: pointer;

			.filter-icon {
				color: var($--theme-core-punch-color);
				margin-right: 4px;

				&.external-icon {
					font-size: 12px;
				}
			}

			.search-filter-title {
				margin: 8px;
				color: var($--theme-core-control-text-color);
			}

			.filter-category-title {
				margin: 8px 0 0 8px;
				color: var($--theme-core-nav-level-2-color-selected);
			}

			.search-filters {
				background-color: var($--theme-core-control-alt-background-color);
				border: 1pt solid var($--theme-core-control-border-color);
				width: 100%;
				padding: 8px;
				border-radius: 0 0 4px 4px;
				display: flex;
				justify-content: baseline;
				flex-wrap: wrap;
				margin-bottom: 8px;

				.input {
					display: inline;

					&:hover {
						cursor: pointer;
						background-color: var($--theme-core-control-hover-background-color);
						color: var($--theme-core-control-focus-color);
					}
				}

				.label {
					margin-left: 4px;
					padding-bottom: 4px;
					color: var($--theme-core-text-color);

					&:hover {
						cursor: pointer;
						background-color: var($--theme-core-control-hover-background-color);
						color: var($--theme-core-control-focus-color);
					}
				}

				.option {
					// display: block;
					margin: 8px;

					&:hover {
						cursor: pointer;
						background-color: var($--theme-core-control-hover-background-color);
						color: var($--theme-core-control-focus-color);
					}
				}
			}
		}

		.no-pointer:hover {
			cursor: auto;
		}

		.search-result {
			display: flex;
			flex-flow: row nowrap;
			gap: 10px;
			margin: 16px 20px;
			cursor: pointer;

			.icon {
				font-size: 12px;
				line-height: 0;
				color: var($--theme-core-punch-color);
			}

			.result-details {
				display: flex;
				flex-flow: column nowrap;
				gap: 4px;
				min-width: 0;
				width: 100%;
				&:hover .relevance-feedback {
					visibility: visible;
					opacity: 1;
					width: 50px;
				}
				&-top {
					display: flex;
					justify-content: space-between;

					.breadcrumb {
						.crumb {
							font-size: 11px;
						}
					}

					.relevance-feedback {
						visibility: hidden;
						display: flex;

						.feedback-button {
							font-size: 15px;
							margin: 0 2px;

							&:hover {
								color: var($--theme-core-control-focus-color);
							}
						}
					}
				}

				.breadcrumb {
					color: var($--theme-core-control-text-color);
				}

				.breadcrumb-container {
					display: inline-flex;
				}

				.before-breadcrumb {
					font-weight: 400;
					font-size: 12px;
					line-height: 14px;
					color: var($--theme-core-text-color);
				}

				&:empty::after {
					content: 'No results';
					font-style: italic;
					color: var($--theme-core-control-border-color);
					display: block;
					padding: 10px;
				}

				.page-title {
					font-size: 14px;
					line-height: 19px;
					color: var($--theme-core-link-color);
				}

				.preview-text {
					font-size: 12px;
					line-height: 16px;
					color: var($--theme-core-control-text-color);

					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}

				.highlight {
					font-weight: bold;
					background-color: var($--theme-searchbar-highlight-background-color);
					color: var($--theme-searchbar-highlight-text-color);
					padding: 2px 4px;
					border-radius: 15%;
				}
			}

			&:hover {
				.page-title {
					color: var($--theme-core-link-hover-color);
				}
			}
		}

		.search-suggestion {
			.clear-history-icon-wrapper {
				position: relative;
			}

			.history-icon-wrapper {
				position: relative;
			}

			.history-icon {
				width: 14px;
				// padding-right: 8px;
				position: absolute;
				top: 5px;
			}

			.history-search-icon {
				position: absolute;
				right: 0;
				top: 3px;
			}

			.clear-history-icon {
				position: absolute;
				font-size: 11px;
				right: 26px;
				top: 2px;
				cursor: pointer;
				color: var($--theme-core-control-border-color);

				&:hover {
					color: var($--theme-core-link-hover-color);
				}
			}

			.history-text {
				margin-left: 24px;
			}

			.buffer {
				margin-left: 9px;
				padding: 16px 20px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				color: var($--theme-core-text-color);

				&.bordered-suggestion {
					border-top: 1pt solid var($--theme-core-layout-border-color);
				}

				&:hover {
					color: var($--theme-searchbar-highlight-text-color);
					background-color: var($--theme-searchbar-highlight-background-color);
				}
			}

			.suggestion-highlight {
				font-weight: bold;
			}
		}
	}

	.search-options {
		display: none;
		label {
			margin-right: 10px;
			color: #333333;
			font-size: 11px;
		}
	}

	.help-search-options-open {
		padding: 3px 0 !important;
	}

	.modal-search-input {
		width: 90%;
	}
}

.search-wrapper {
	display: flex;
	width: $totalWidth;
	align-items: center;

	@media screen and (max-width: 1380px) {
		width: $totalWidthM;
	}

	@media screen and (max-width: 1300px) {
		width: $totalWidthS;
	}
}
